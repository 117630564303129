import React, { useState, useEffect } from 'react';
import { Nav } from 'react-bootstrap';
import { Link } from 'gatsby';

import { menuLinks } from '../common/menuUtils';
import { PlusIcon, MinusIcon } from './MenuIcons';

// Styles
import '../Menu/Menu.scss';

const Menu = ({ menuData, isAuth }) => {
  console.log('Menus', menuData);

  const [isAuthState, setIsAuth] = useState(false);

  useEffect(() => {
    setIsAuth(isAuth);
  }, [isAuth]);

  const [openMenus, setOpenMenus] = useState({});
  const [hoveredParent, setHoveredParent] = useState(null);

  const toggleSubMenu = (childId) => {
    setOpenMenus((prev) => ({
      ...prev,
      [childId]: !prev[childId],
    }));
  };

  // Check if menuData exists
  const renderMenuItems = (menuData) => {
    return menuData.map((parent) => (
      <li
        key={parent.id}
        onMouseEnter={() => setHoveredParent(parent.id)}
        onMouseLeave={() => setHoveredParent(null)}
        className="parent-item"
      >
        <a href={
    parent?.Parent_Link && parent?.Parent_Menu_Link?.Primary_URL 
    ? `${parent.Parent_Link}/${parent.Parent_Menu_Link.Primary_URL}/` 
    : parent?.Parent_Menu_Link?.Primary_URL 
        ? `/${parent.Parent_Menu_Link.Primary_URL}/` 
        : '#'
        }className="parent-link">
          {parent.Parent_Label}
        </a>
        {hoveredParent === parent.id && parent.Child_Menu.length > 0 && (
          <ul className="child-menu">
            {parent?.Child_Menu.map((child) => (
              <li key={child.id} className="child-item">
                <div className="child-header">
                  <a href={child.Child_Link || '#'} className="child-link">
                    {child.Child_Label}
                  </a>
                  {child.Child_Sub_Menu.length > 0 && (
                    <button onClick={() => toggleSubMenu(child.id)} className="toggle-button">
                      {openMenus[child.id] ? <MinusIcon /> : <PlusIcon />}
                    </button>
                  )}
                </div>
                {openMenus[child.id] && (
                  <ul className="sub-menu">
                    {child.Child_Sub_Menu.map((sub) => (
                      <li key={sub.id} className="sub-item">
                        <a
                          href={sub.Child_Sub_Menu_Link || sub.Child_Sub_Menu_Menu_Link?.Primary_URL || '#'}
                          className="sub-link"
                        >
                          {sub.Child_Sub_Menu_Label}
                        </a>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        )}
      </li>
    ));
  };

  return (
    <Nav className={`navigation-menu ${isAuthState ? 'loggedin_menu' : ''}`}>
      <ul className="parent-menu">
        {menuData && menuData.length > 0 ? (
          renderMenuItems(menuData)
        ) : (
          <li>No menu data available</li>
        )}
      </ul>
    </Nav>
  );
};

export default Menu;